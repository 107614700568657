<template>
  <div>
    <!--Modal AddEditLocalVotacion-->
    <div v-if="edit.mostrarModal">
      <add-edit-local-votacion v-model="edit.mostrarModal" :local-votacion-id="edit.localVotacionId" @onSave="handleAddEditLocalVotacionSaved"></add-edit-local-votacion>
    </div>
    <!--Modal Nivel Acceso-->
    <div v-if="editNivelAcceso.mostrarModal">
      <edit-nivel-acceso v-model="editNivelAcceso.mostrarModal" :local-votacion-id="editNivelAcceso.localVotacionId" @onSave="handleNivelAccesoSaved"></edit-nivel-acceso>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label"><i class="fas fa-filter"></i> Filtros</h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <label class="font-weight-bold">Código de Local</label>
                  <b-form-input id="input-1" v-model="filter.codigo" required placeholder="Código"></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Local de votación</label>
                  <b-form-input id="input-2" v-model="filter.descripcion" required placeholder="Nombre o dirección"></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Región</label>
                  <select class="form-control" v-model="filter.regionId">
                    <option :value="null">- Seleccionar -</option>
                    <option v-for="region in regiones" :value="region.regionId" :key="region.regionId">{{ region.nombre }}</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold"> Sub región</label>
                  <select class="form-control" v-model="filter.subRegionId">
                    <option :value="null">- Seleccionar -</option>
                    <option v-for="subRegion in subRegiones" :value="subRegion.subRegionId" :key="subRegion.subRegionId">{{ subRegion.nombre }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a href="#" class="btn btn-primary font-weight-bold mr-2 alignment-right" @click.prevent="fetchLocalesVotacion()"><i class="fa fa-search"></i> Buscar</a>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label"><i class="la la-building"></i> Locales de Votación</h3>
            </div>
            <div class="card-toolbar">
              <a href="#" class="btn btn-primary font-weight-bold mr-2" @click.prevent="doAddEditLocalVotacion()"><i class="la la-plus"></i> Nuevo</a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center table-head-bg table-hover" style="min-height: 150px;">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>
                      Local / Dirección
                    </th>
                    <th>
                      Sub Región / Región
                    </th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="local in locales" :key="local.localVotacionId">
                    <td>
                      {{ local.codigo }}
                    </td>
                    <td>
                      {{ local.nombre }}<br />
                      <small class="text-muted">{{ local.direccion }}</small>
                    </td>
                    <td>
                      {{ local.subRegionNombre }}<br />
                      <small class="text-muted">{{ local.regionNombre }}</small>
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item href="#" @click.prevent="doAddEditLocalVotacion(local.localVotacionId)"
                          ><span class="navi-icon"><i class="la la-pen text-dark"></i> Editar</span></b-dropdown-item
                        >
                        <b-dropdown-item href="#" @click.prevent="doAddEditNivelAcceso(local.localVotacionId)"
                          ><span class="navi-icon"><i class="la la-level-down-alt text-dark"></i> Nivel Acceso</span></b-dropdown-item
                        >
                        <b-dropdown-item href="#" @click.prevent="doFichaSeguimientoElectoral(local.localVotacionId)"
                          ><span class="navi-icon"><i class="la la-clipboard-list text-dark"></i> Ficha</span></b-dropdown-item
                        >
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cell-width-10 {
  width: 10%;
}

.cell-width-60 {
  width: 60%;
}

.cell-width-20 {
  width: 20%;
}

.alignment-right {
  float: right;
}

.padding-t-b {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import addEditLocalVotacion from "@/components/location/add-edit-local-votacion";
import editNivelAcceso from "@/components/location/edit-nivel-acceso";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import { INACTIVE_USER_TIME_THRESHOLD, USER_ACTIVITY_THROTTLER_TIME } from "@/helpers/constants.js";

export default {
  name: "list-local-votacion",
  components: {
    addEditLocalVotacion,
    editNivelAcceso,
  },
  props: {},
  data: () => ({
    locales: [],
    edit: {
      mostrarModal: false,
      localVotacionId: null,
    },
    editNivelAcceso: {
      mostrarModal: false,
      localVotacionId: null,
    },
    pagination: {
      pageSize: 10,
    },
    filter: {
      regionId: null,
      subRegionId: null,
      codigo: "",
      descripcion: "",
    },
    regiones: [],
    subRegiones: [],
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
    localesVotacion: [],
  }),
  computed: {},
  methods: {
    doAddEditLocalVotacion: function(localVotacionId) {
      this.edit.localVotacionId = localVotacionId;
      this.edit.mostrarModal = true;
    },
    doAddEditNivelAcceso: function(localVotacionId) {
      this.editNivelAcceso.localVotacionId = localVotacionId;
      this.editNivelAcceso.mostrarModal = true;
    },
    doFichaSeguimientoElectoral: function(localVotacionId) {
      this.$router.push({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localVotacionId,
        },
      });
    },
    handleAddEditLocalVotacionSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchLocalesVotacion();
    },
    handleNivelAccesoSaved: function(obj) {
      //alert(JSON.stringify(obj));
      // this.fetchLocalesVotacion();
    },
    fetchLocalesVotacion: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
      };

      //alert(JSON.stringify(model));

      this.$http.post(this.apiRoute("Location", "ListLocalesVotacion"), model).then(function(response) {
        this.locales = response.body.data.items;
        this.pagination = response.body.data.pagination;
      });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http.post(this.apiRoute("Region", "ListRegiones"), modelRegion).then(function(response) {
        this.regiones = response.body.data.items;
      });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Locales de Votacion" }]);
    this.fetchRegiones();
    this.fetchLocalesVotacion();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
