<template>
  <b-modal
    id="modal-add-edit-nivel-acceso"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="la la-level-down-alt text-dark"></i>
          Gestión de Acceso
        </span>
      </div>
    </template>

    <div class="alert alert-info">
      Defina los usuarios que podrán gestionar este local de votación.
    </div>

    <b-form id="frm-add-edit-nivel-acceso">
      <div
        class="row pt-3"
        v-for="nivel in nivelesAcceso"
        :key="nivel.nivelAccesoId"
      >
        <div class="col-md-12">
          <label class="font-weight-bold">{{ nivel.nombre }}</label>
          <treeselect
            :multiple="true"
            :options="usuarios"
            placeholder="Buscar usuarios..."
            v-model="nivel.usuariosId"
          >
          </treeselect>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        @click.prevent="doSave()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "edit-nivel-acceso",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    localVotacionId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    permisoEdicion: false,
    nivelesAcceso: [],
    usuarios: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      //debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-nivel-acceso")) {
        return this.sweetAlert(
          "Hay campos con valores incorrectos. Revise los datos ingresados."
        );
      }

      var model = {
        LocalVotacionId: this.localVotacionId,
        ListNivelAccesoLocalVotacion: this.nivelesAcceso,
      };

      await this.$http
        .post(
          this.apiRoute("AccessLevel", "EditNivelAccesoLocalVotacion"),
          model
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            this.$emit("onSave", { localVotacionId: this.localVotacionId });
            this.mostrarModal = false;
          }
        }, this.mostrarMensajeExcepcionVueResource);
    },

    fetchNivelAcceso: function() {
      var model = {
        LocalVotacionId: this.localVotacionId,
      };

      this.$http
        .get(this.apiRoute("AccessLevel", "GetNivelAccesoLocalVotacionEdit"), {
          params: model,
        })
        .then(function(response) {
          this.nivelesAcceso = response.body.data;
        });
    },
    fetchUsuarios: function() {
      var model = {
        paging: false,
      };

      this.$http
        .post(this.apiRoute("User", "ListUsuarios"), model)
        .then(function(response) {
          this.usuarios = response.body.data.items.map(function(x) {
            return {
              id: x.usuarioId,
              label: x.nombreCompleto,
            };
          });
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.initParsley();
    this.fetchUsuarios();
    this.fetchNivelAcceso();
  },
  destoyed: function() {},
};
</script>
