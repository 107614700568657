<template>
  <b-modal id="modal-add-edit-local-votacion" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"><i class="la la-building"></i> {{ localVotacionId ? "Editar" : "Agregar" }} Local de Votación </span>
      </div>
    </template>
    <!--Contenido de modal de | ID :
    {{ localVotacionId }}-->
    <b-form id="frm-add-edit-local-votacion">
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Código</label>
          <b-form-input id="input-3" v-model="localVotacion.codigo" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Nombre</label>
          <b-form-input id="input-4" v-model="localVotacion.nombre" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Dirección</label>
          <b-form-input id="input-5" v-model="localVotacion.direccion" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"> Región</label>
          <select class="form-control" v-model="localVotacion.regionId">
            <option :value="null">- Seleccionar -</option>
            <option v-for="region in regiones" :value="region.regionId" :key="region.regionId">{{ region.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold"> Sub - Región</label>
          <select class="form-control" v-model="localVotacion.subRegionId">
            <option :value="null">- Seleccionar -</option>
            <option v-for="subRegion in subRegiones" :value="subRegion.subRegionId" :key="subRegion.subRegionId">{{ subRegion.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox id="checkbox-1" size="lg" v-model="localVotacion.esEliminado"> </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-save"></i> Guardar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-local-votacion",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    localVotacionId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    localVotacion: {
      localVotacionId: null,
      codigo: "",
      nombre: "",
      direccion: "",
      regionId: null,
      subRegionId: null,
      esEliminado: false,
    },
    regiones: [],
    subRegiones: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-local-votacion")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      var model = {
        ...this.localVotacion,
      };

      this.estaGuardando = true;
      await this.$http.post(this.apiRoute("Location", "AddEditLocalVotacion"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.sweetAlert(response.body.message, "success");
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
    fetchLocalVotacion: function(localVotacionId) {
      if (localVotacionId) {
        this.$http
          .get(this.apiRoute("Location", "GetLocalVotacionById"), {
            params: {
              LocalVotacionId: localVotacionId,
            },
          })
          .then(function(response) {
            this.localVotacion = response.body.data;
          });
      }
    },
    fetchRegiones: function() {
      var modelRegion = {};

      this.$http.post(this.apiRoute("Region", "ListRegiones"), modelRegion).then(function(response) {
        this.regiones = response.body.data.items;
      });
    },
    fetchSubRegiones: function(regionId) {
      debugger;
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
    "localVotacion.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.localVotacion.localVotacionId = this.localVotacionId;
    this.initParsley();
    this.fetchRegiones();
    this.fetchLocalVotacion(this.localVotacion.localVotacionId);
  },
  destoyed: function() {},
};
</script>
